.allApps-parent {
  margin-left: 6em;
  margin-right: -12px;
  padding-left: 64px;
  padding-right: 64px;
  width: 90%;
}
.allApps-container {
  grid-template-columns: repeat(auto-fill, minmax(294.4px, 1fr));

  display: grid;

  grid-column-gap: 13px;
  grid-row-gap: 9px;
}
.allApps-parent h1 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 23px;
  border-bottom: 1px solid #e6e4e4;
  color: #474747;
}
.allApps-item {
  /* display: flex;
  height: 70px;
  width: 200px; */
  display: flex;
  flex-direction: row;
  /* height: 70px; */
  overflow: hidden;
  padding: 15px;
  text-align: left;
  transition: none;
  width: 100%;
  cursor: pointer;
}
.allApps-detail {
  display: flex;
  flex-direction: column;
  color: #242424;
}
.allApps-item img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.allApps-name {
  font-size: 16px;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.allApps-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  line-height: 15px;
  width: 100%;
}
.allApps-item a {
  display: flex;
}
.allApps-item:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.allApps-item .allApps-description {
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
}
@media (min-width: 320px) {
  .allApps-parent {
    padding-left: 0;
  }
}
