@tailwind components;
@layer components {
  .option {
    @apply cursor-pointer hover:bg-gray-100 transition duration-200 ease-out p-2 rounded-lg;
  }
}

:root {
  --primary-color: #00996b;
  --dark-color: #00685c;
}

* {
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.green {
  background: #00996b !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.green :hover {
  background: #00685c !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #04796a !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: #04796a29 !important;
}
.MuiSnackbarContent-root {
  background-color: white !important;
  color: black !important;
}
.centered-div {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  background: white;
  left: 50%;
  margin: -100px 0 0 -150px;
  text-align: center;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
}

.page-height {
  height: 1066px !important;
  width: 826px !important;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: 0 !important;
}
.editor-toolbar-top {
  top: 56.5px !important;
}

.tab-icon {
  margin-top: 10px;
}

.x-spreadsheet-sheet {
  height: 83vh !important;
}

.equal-spaced-view {
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2));
}

button:focus {
  outline: none;
}
select:focus {
  outline: none;
}
.side-bar {
  height: 84vh;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 113px;
  background: white;
  width: 400px;
  text-align: left;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  background-color: #fff;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 15px;
}
.side-bar-full {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  text-align: left;
  z-index: 1;
  background: white;
}

.MuiTab-wrapper {
  width: 100%;
  display: table !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.headerImage {
  max-height: 22.5vw;
  /* max-width: 90vw; */
  height: 160px;
  /* width: 640px; */
  background-size: cover;
  background-position: center;
}
.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
}
.rounded-paper {
  margin-top: 1em;
}

.question-text {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #202124;
  min-width: 0%;
}
.capitalize {
  text-transform: none;
}
.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  color: black !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: black !important;
}

.table-style {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  background-color: #f8f9fa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 10px;
  white-space: pre-line;
  margin-bottom: 10px;
  cursor: pointer;
}
.MuiTableCell-root {
  border-bottom: 5px solid white !important;
}

.section-title {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #202124;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.add-hover:hover {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}
.default-spinner {
  width: 40px;
  height: 40px;
  position: fixed;
  margin-left: 40%;
  margin-top: 45vh;
}
@media (max-width: 900px) {
  .add-file-section {
    margin-left: 2em;
  }
  .file-list-section {
    margin-left: 1.7em;
  }
  .search-section {
    visibility: hidden;
  }
  .menu-icon {
    display: none !important;
  }
}
